<template>
  <div id="ProductDetail" class="section">
    <div class="columns is-multiline is-vcentered">
      <!-- Back Button -->
      <div class="column is-1">
        <div @click.prevent="navigateToProductManage">
          <b-icon class="clickable-icon" icon="chevron-left" size="is-medium" />
        </div>
      </div>
      <!-- Title -->
      <div class="column is-7-desktop is-7-tablet is-12-mobile">
        <h1 class="title">PRODUCT DETAILS</h1>
      </div>
      <!-- Create Button -->
      <div
        class="column is-2 is-hidden-mobile"
        :class="productObject ? '' : 'is-offset-2'"
      >
        <b-button
          expanded
          type="is-primary"
          icon-left="upload"
          :disabled="disableCreate"
          @click.prevent="uploadProductButtonPressed"
          >{{ productObject ? "Update" : "Create" }}</b-button
        >
      </div>
      <!-- Remove Button -->
      <div v-if="productObject" class="column is-2 is-hidden-mobile">
        <b-button
          expanded
          outlined
          type="is-danger"
          icon-left="close"
          @click.prevent="presentConfirmRemoveProduct"
          >Remove</b-button
        >
      </div>
    </div>

    <div class="container">
      <div style="margin-top: 1.5rem">
        <div class="columns">
          <!-- MAIN IMAGE -->
          <div class="column is-5 has-text-left">
            <!-- Image Upload -->
            <b-field label="Banner Image" />
            <image-file-upload
              :mainImageUrl="mainImage"
              @uploaded_file="val => (mainImage = val)"
            />
            <p style="padding-top: 1rem; color: #676767">
              Aspect Ratio 1:1 ( recommended 1024x1024px ) max size: 2048px
            </p>
          </div>
          <!-- PRODUCT DETAILS -->
          <div class="column">
            <!-- Image Files Upload -->
            <multi-image-file-upload
              :type="'product'"
              :existingContentUrls="photos"
              @uploaded_files="setUploadedContentImages"
            />
            <!-- Title + Subtitle + Type -->
            <b-tabs type="is-boxed" style="padding: 1rem 0 0 0">
              <!-- ENGLISH -->
              <b-tab-item label="English">
                <b-field label="Name">
                  <b-input v-model="en_name" />
                </b-field>
                <b-field label="Subtitle">
                  <b-input v-model="en_subtitle" type="textarea" />
                </b-field>
                <b-field label="Description">
                  <b-input v-model="en_description" type="textarea" />
                </b-field>
                <b-field label="Category">
                  <b-select
                    expanded
                    placeholder="Select a category"
                    v-model="selectedCategory"
                  >
                    <option
                      v-for="option in categories"
                      :value="option._id"
                      :key="option._id"
                    >
                      {{ option.title.en }}
                    </option>
                  </b-select>
                </b-field>
              </b-tab-item>
              <!-- CHINESE -->
              <b-tab-item label="Chinese">
                <b-field label="Name">
                  <b-input v-model="zh_name" />
                </b-field>
                <b-field label="Subtitle">
                  <b-input v-model="zh_subtitle" type="textarea" />
                </b-field>
                <b-field label="Description">
                  <b-input v-model="zh_description" type="textarea" />
                </b-field>
                <b-field label="Category">
                  <b-select
                    expanded
                    placeholder="Select a category"
                    v-model="selectedCategory"
                  >
                    <option
                      v-for="option in categories"
                      :value="option._id"
                      :key="option._id"
                    >
                      {{ option.title.en }}
                    </option>
                  </b-select>
                </b-field>
              </b-tab-item>
              <!-- THAI -->
              <b-tab-item label="Thai">
                <b-field label="Name">
                  <b-input v-model="th_name" />
                </b-field>
                <b-field label="Subtitle">
                  <b-input v-model="th_subtitle" type="textarea" />
                </b-field>
                <b-field label="Description">
                  <b-input v-model="th_description" type="textarea" />
                </b-field>
                <b-field label="Category">
                  <b-select
                    expanded
                    placeholder="Select a category"
                    v-model="selectedCategory"
                  >
                    <option
                      v-for="option in categories"
                      :value="option._id"
                      :key="option._id"
                    >
                      {{ option.title.en }}
                    </option>
                  </b-select>
                </b-field>
              </b-tab-item>
            </b-tabs>
            <!-- STOCK & PRICE & PRE-ORDER -->
            <div class="columns is-vcentered is-multiline">
              <div class="column is-12">
                <b-field label="Product Price Details"></b-field>
              </div>
              <div class="column">
                <b-field label="Original Price">
                  <b-input type="number" v-model="original_price" />
                </b-field>
              </div>
              <div class="column">
                <b-field label="Discount Price">
                  <b-input type="number" v-model="discount_price" />
                </b-field>
              </div>
              <div class="column">
                <b-field label="In-Stock">
                  <b-input type="number" v-model="instock" />
                </b-field>
              </div>
              <div class="column">
                <b-field label="Option">
                  <b-checkbox v-model="isPreorder"> Pre-Order </b-checkbox>
                </b-field>
              </div>
            </div>
            <!-- Shipping Price -->
            <div class="columns is-multiline">
              <div class="column is-12">
                <b-field label="Shipping Price Details"></b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 0">
                  <b-input v-model="shipping_zone0" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 1">
                  <b-input v-model="shipping_zone1" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 2">
                  <b-input v-model="shipping_zone2" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 3">
                  <b-input v-model="shipping_zone3" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 4">
                  <b-input v-model="shipping_zone4" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 5">
                  <b-input v-model="shipping_zone5" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 6">
                  <b-input v-model="shipping_zone6" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 7">
                  <b-input v-model="shipping_zone7" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 8">
                  <b-input v-model="shipping_zone8" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 9">
                  <b-input v-model="shipping_zone9" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 10">
                  <b-input v-model="shipping_zone10" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 11">
                  <b-input v-model="shipping_zone11" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 12">
                  <b-input v-model="shipping_zone12" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 13">
                  <b-input v-model="shipping_zone13" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 14">
                  <b-input v-model="shipping_zone14" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 15">
                  <b-input v-model="shipping_zone15" />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Zone 16">
                  <b-input v-model="shipping_zone16" />
                </b-field>
              </div>
            </div>
            <!-- RELATED PRODUCT -->
            <div class="columns is-multiline">
              <div class="column is-12">
                <b-field label="Recommended Product List"></b-field>

                <b-dropdown
                  v-model="relate_products"
                  multiple
                  aria-role="list"
                  position="is-top-right"
                >
                  <template #trigger>
                    <b-button type="is-primary" icon-right="menu-down">
                      Selected ({{ relate_products.length }})
                    </b-button>
                  </template>
                  <b-dropdown-item
                    v-for="product in products"
                    aria-role="listitem"
                    :key="product._id"
                    :value="product._id"
                  >
                    <div style="padding: 0.5rem 0.25rem">
                      <span> {{ product.name.en }} </span>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="column is-12">
                <p
                  v-for="selected in display_relate_products"
                  class="content"
                  :key="selected"
                >
                  {{ selected }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile : Create Button -->
      <div class="is-hidden-tablet" style="margin-top: 1.5rem">
        <b-button
          expanded
          type="is-primary"
          @click.prevent="uploadProductButtonPressed"
          >{{ productObject ? "Update" : "Create" }}</b-button
        >
      </div>
      <!-- Mobile: Remove Button -->
      <div
        v-if="productObject"
        class="is-hidden-tablet"
        style="margin-top: 1rem"
      >
        <b-button
          expanded
          outlined
          type="is-danger"
          icon-left="close"
          @click.prevent="presentConfirmRemoveProduct"
          >Remove</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ProductApi from "@/apis/ProductApi";
import CategoryApi from "@/apis/CategoryApi";
import AlertManager from "@/utils/AlertManager";
import ImageFileUpload from "@/components/forms/ImageFileUpload";
import MultiImageFileUpload from "@/components/forms/MultiImageFileUpload";

export default {
  name: "ProductDetail",
  components: {
    ImageFileUpload,
    MultiImageFileUpload
  },
  props: ["product"],
  data() {
    return {
      products: [],
      relate_products: [],
      display_relate_products: [],
      mainImage: null,
      en_name: null,
      en_subtitle: null,
      en_description: null,
      zh_name: null,
      zh_subtitle: null,
      zh_description: null,
      th_name: null,
      th_subtitle: null,
      th_description: null,
      instock: null,
      original_price: null,
      discount_price: null,
      photos: [],
      uploadPhotos: [],
      isPreorder: false,
      categories: null,
      shipping_zone0: null,
      shipping_zone1: null,
      shipping_zone2: null,
      shipping_zone3: null,
      shipping_zone4: null,
      shipping_zone5: null,
      shipping_zone6: null,
      shipping_zone7: null,
      shipping_zone8: null,
      shipping_zone9: null,
      shipping_zone10: null,
      shipping_zone11: null,
      shipping_zone12: null,
      shipping_zone13: null,
      shipping_zone14: null,
      shipping_zone15: null,
      shipping_zone16: null,
      selectedCategory: null,
      productObject: this.product
    };
  },
  computed: {
    disableCreate: function() {
      if (
        (this.en_name &&
          this.en_subtitle &&
          this.en_description &&
          this.zh_name &&
          this.zh_subtitle &&
          this.zh_description &&
          this.th_name &&
          this.th_subtitle &&
          this.th_description &&
          this.instock &&
          this.original_price &&
          this.mainImage) ||
        this.productObject
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    relate_products: function(val) {
      if (val) {
        let result = [];
        val.forEach(product_id => {
          let matched = this.products.find(
            product => product._id === product_id
          );
          result.push(matched.name.en);
        });
        this.display_relate_products = result;
      }
    }
  },
  methods: {
    setUploadedContentImages: function(urls) {
      this.uploadPhotos = urls;
    },
    fillDetailForm: function() {
      this.mainImage = this.productObject.main_image;

      this.en_name = this.productObject.name.en;
      this.en_subtitle = this.productObject.sub_title.en;
      this.en_description = this.productObject.description.en;

      this.zh_name = this.productObject.name.zh;
      this.zh_subtitle = this.productObject.sub_title.zh;
      this.zh_description = this.productObject.description.zh;

      this.th_name = this.productObject.name.th;
      this.th_subtitle = this.productObject.sub_title.th;
      this.th_description = this.productObject.description.th;

      this.instock = this.productObject.instock;
      this.isPreorder = this.productObject.preorder;
      this.selectedCategory = this.productObject.cat_id;
      this.shipping_zone0 = this.productObject.shipping.zone0;
      this.shipping_zone1 = this.productObject.shipping.zone1;
      this.shipping_zone2 = this.productObject.shipping.zone2;
      this.shipping_zone3 = this.productObject.shipping.zone3;
      this.shipping_zone4 = this.productObject.shipping.zone4;
      this.shipping_zone5 = this.productObject.shipping.zone5;
      this.shipping_zone6 = this.productObject.shipping.zone6;
      this.shipping_zone7 = this.productObject.shipping.zone7;
      this.shipping_zone8 = this.productObject.shipping.zone8;
      this.shipping_zone9 = this.productObject.shipping.zone9;
      this.shipping_zone10 = this.productObject.shipping.zone10;
      this.shipping_zone11 = this.productObject.shipping.zone11;
      this.shipping_zone12 = this.productObject.shipping.zone12;
      this.shipping_zone13 = this.productObject.shipping.zone13;
      this.shipping_zone14 = this.productObject.shipping.zone14;
      this.shipping_zone15 = this.productObject.shipping.zone15;
      this.shipping_zone16 = this.productObject.shipping.zone16;
      this.photos = this.productObject.photos;

      if (
        this.productObject.full_price &&
        this.productObject.full_price !== -1 &&
        this.productObject.full_price !== this.productObject.price
      ) {
        this.original_price = this.productObject.full_price;
        this.discount_price = this.productObject.price;
      } else {
        this.original_price = this.productObject.price;
      }

      this.productObject.relate_products.forEach(product => {
        this.relate_products.push(product._id);
      });
    },

    uploadProductButtonPressed: async function() {
      let params = {
        cat_id: this.selectedCategory,
        main_image: this.mainImage,
        name: {
          en: this.en_name,
          th: this.th_name,
          zh: this.zh_name
        },
        sub_title: {
          en: this.en_subtitle,
          th: this.th_subtitle,
          zh: this.zh_subtitle
        },
        description: {
          en: this.en_description,
          th: this.th_description,
          zh: this.zh_description
        },
        instock: this.instock,
        preorder: this.isPreorder,
        shipping: {
          zone0: Number(this.shipping_zone0),
          zone1: Number(this.shipping_zone1),
          zone2: Number(this.shipping_zone2),
          zone3: Number(this.shipping_zone3),
          zone4: Number(this.shipping_zone4),
          zone5: Number(this.shipping_zone5),
          zone6: Number(this.shipping_zone6),
          zone7: Number(this.shipping_zone7),
          zone8: Number(this.shipping_zone8),
          zone9: Number(this.shipping_zone9),
          zone10: Number(this.shipping_zone10),
          zone11: Number(this.shipping_zone11),
          zone12: Number(this.shipping_zone12),
          zone13: Number(this.shipping_zone13),
          zone14: Number(this.shipping_zone14),
          zone15: Number(this.shipping_zone15),
          zone16: Number(this.shipping_zone16)
        },
        relate_products: this.relate_products
      };

      if (
        this.discount_price &&
        this.discount_price > 0 &&
        this.original_price > this.discount_price
      ) {
        params.full_price = this.original_price;
        params.price = this.discount_price;
      } else {
        params.full_price = -1;
        params.price = this.original_price;
      }

      // Append existing photos
      params.photos = [];
      for (let index = 0; index < this.photos.length; index++) {
        const existing_photo = this.photos[index];
        params.photos.push(existing_photo);
      }

      // Upload News' content images
      if (this.uploadPhotos.length > 0) {
        // Upload new images the append return url to params
        for (let index = 0; index < this.uploadPhotos.length; index++) {
          const new_photo = this.uploadPhotos[index];
          params.photos.push(new_photo);
        }
      }

      // Conditional update if _id is exist, then will create instead
      if (this.productObject) {
        this.updateExistingProduct(this.productObject._id, params);
      } else {
        this.createNewProduct(params);
      }
    },
    createNewProduct: async function(productInfo) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await ProductApi.requestCreateProduct(productInfo);
      loadingComponent.close();
      AlertManager.showSuccessSnackbar(
        "Product " + response.name + " was scucessfully created."
      );
      if (response) {
        this.productObject = response;
        this.navigateToProductManage();
      }
    },
    updateExistingProduct: async function(product_id, productInfo) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      await ProductApi.requestUpdateProductById(product_id, productInfo);
      loadingComponent.close();
      AlertManager.showSuccessSnackbar("Product was scucessfully updated.");
    },
    presentConfirmRemoveProduct: function() {
      if (this.productObject._id) {
        this.$buefy.dialog.confirm({
          title: "Confirm Remove Product",
          message:
            "Are you sure you want to permanently remove <b>" +
            this.productObject.name.en +
            "</b>?",
          confirmText: "Confirm",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.removeProduct(this.productObject._id)
        });
      }
    },
    removeProduct: async function(product_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await ProductApi.requestRemoveProductById(product_id);
      loadingComponent.close();
      if (response) {
        this.navigateToProductManage();
      }
    },
    getProductList: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await ProductApi.requestProductList();
      loadingComponent.close();
      if (response && response.length > 0) {
        this.products = response;
      }
    },
    getProductDetail: async function(product_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await ProductApi.requestProductById(product_id);
      loadingComponent.close();
      this.productObject = response;
      this.fillDetailForm();
    },
    requestCategoryList: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CategoryApi.requestCategoryList();
      loadingComponent.close();
      this.categories = response;
    },
    navigateToProductManage: function() {
      this.$router.push({ name: "product" });
    }
  },
  mounted() {
    this.getProductList();
    this.requestCategoryList();
    if (this.productObject) {
      const product_id = this.productObject._id;
      this.getProductDetail(product_id);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#ProductDetail {
  text-align: left;
}
.clickable-icon {
  cursor: pointer;
}
</style>
